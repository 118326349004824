import TagPageTemplate from '@/templates/tag';
import { ArticleTag, GatsbyPageProps } from '@/types';

export default function BitcoinPage(props: GatsbyPageProps) {
  return (
    <TagPageTemplate
      location={props.location}
      title="Crypto RSI Chart"
      tag={ArticleTag.RSI}
    />
  );
}
